import { validateProps } from "../../common/types";

export default function validate(values: validateProps) {
  let errors = {} as validateProps;

  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }
  if (!values.message) {
    errors.message = "Message is required";
  }
  return errors;
}


export function validateEmail(value: string){
  if (!value) {
    return "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(value)) {
    return "Email address is invalid";
  }

  return ""
}

export function validatePassword(value: string){
  if (!value) {
    return "Password is required";
  } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#$%]).{8,24}$/.test(value)) {
    return "The password must contains at list 8 characters and include uppercase and lowercase letters, a number and a special character.";
  }

  return ""
}

export function validateConfirmPassword(password:string ,value: string){
  if (!value) {
    return "Confirm password is required";
  } else if (password !==value) {
    return "The password and the confirm password must be the same";
  }

  return ""
}

export const RELAY_MATE_EXTENSION_URL = "https://chrome.google.com/webstore/detail/relaymate/gpkiojbflfpjlackmjdgaohmcacoihfn";

export const API_BASE_URL = "https://relay-mate.herokuapp.com"
export const API_VERSION = "/api/v1"
export const RELAY_LOAD_BORD_URL = "https://relay.amazon.co.uk/loadboard/search"
export const NON_HEADER_LIST = [
    "/reset-password",
    "/reset-password/email",
    "/admin/login",
    "/admin/dashboard",
    "/terms-and-conditions",
    "/privacy-policy"
]
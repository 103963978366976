import { Card, Col, Form, Input, Row, message } from "antd";
import { useState } from "react";
import api from "../../common/utils/api";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { MiddleBlockSection } from "../../components/MiddleBlock/styles";

export interface TermsProps {
    t: any
}

const TermsBlock = ({
    t,
}: TermsProps) => {

    return (
        <>
            <Header />
            <div style={{margin:'5%'}}>
                Welcome to RelayMate! These Terms and Conditions ("Terms") govern your use of the RelayMate service, including our website and any related applications (collectively, the "Service").
                By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use RelayMate.

                <p style={{marginTop: '1%',marginBottom: '0%'}}> 1. Introduction</p>


                The RelayMate website and service (hereinafter referred to as "RelayMate," "we," "us," or "our") provide users with a platform to book loads for Amazon Relay and offer related services. These Terms outline the rules and guidelines for using RelayMate. Please read them carefully.

                <p style={{marginTop: '1%',marginBottom: '0%'}}>
                    2. No Refunds</p>

                We understand that situations may change, and plans may need to be adjusted. However, we regret to inform you that RelayMate does not offer refunds for ticket purchases or any other transactions conducted on our platform. All sales are final. Please consider your purchase carefully before proceeding.

                <p style={{marginTop: '1%',marginBottom: '0%'}}>
                    3. Free Trial Policy</p>

                a. RelayMate offers a one-time free trial for each client account. This free trial is provided to allow users to explore our platform and understand its capabilities. By creating an account and using the free trial, you agree to these Terms and Conditions.

                b. Suspected Abuse: We reserve the right to investigate and monitor accounts that we suspect may be attempting to abuse the free trial policy. This includes but is not limited to creating multiple accounts to access multiple free trials. If we find evidence of such abuse, we may take appropriate action, which may include closing the accounts involved without notice.

                <p style={{marginTop: '1%',marginBottom: '0%'}}>4. Updates to Amazon Relay Page</p>

                a. Ticket Purchase: RelayMate enables users to purchase tickets for Amazon Relay. Please note that once a ticket is bought, RelayMate is not responsible for any updates or changes made by the Amazon Relay page.

                b. No Refunds for Page Updates: In the event that the Amazon Relay page is updated or altered after the ticket is purchased through RelayMate, we regret to inform you that RelayMate will not offer refunds. It is your responsibility to monitor and manage any changes that may affect your ticket.

                <p style={{marginTop: '1%',marginBottom: '0%'}}>5. Account Termination</p>

                RelayMate reserves the right to terminate or suspend your account and access to the Service, with or without notice, for any violation of these Terms or for any other reason deemed necessary by RelayMate. You agree that RelayMate shall not be liable to you or any third party for any termination of your access to the Service.

                <p style={{marginTop: '1%',marginBottom: '0%'}}>6. Privacy Policy</p>

                Your use of RelayMate is also governed by our Privacy Policy, which is incorporated by reference into these Terms. Please review the Privacy Policy to understand how we collect, use, and disclose information about you.

                <p style={{marginTop: '1%',marginBottom: '0%'}}>7. Changes to Terms</p>

                RelayMate reserves the right to modify or revise these Terms at any time. Any changes will be effective immediately upon posting on this page. You are responsible for regularly reviewing these Terms, and your continued use of the Service after any changes indicates your acceptance of the revised Terms.

                <p style={{marginTop: '1%',marginBottom: '0%'}}>8. Contact Us</p>

                If you have any questions, concerns, or feedback regarding these Terms and Conditions, please contact us at relaymate.contact@gmail.com.

                <p style={{marginTop: '1%',marginBottom: '0%'}} >9. Conclusion</p>

                By using RelayMate, you agree to abide by these Terms and Conditions. Please read and understand these Terms carefully before using our service. RelayMate aims to provide a convenient platform for booking loads from Amazon Relay, and we appreciate your cooperation in maintaining a fair and trustworthy environment for all users.

                Thank you for choosing RelayMate. We look forward to serving you!
            </div>
            <Footer />
        </>
    );
}

export default withTranslation()(TermsBlock);
import styled from "styled-components";

export const LoginWrapper = styled("div")`
.link-redirect {
  :hover {
    text-decoration: underline;
  }
}

.row-size {
  margin: 0% 30%;

  @media only screen and (max-width: 1000px) {
    margin: 0% 15%;
  }

  @media only screen and (max-width: 600px) {
    margin: 0% 0%;
  }
}
`
import { lazy, useState } from "react";
import { Col, Row } from "antd";
import { Slide, Zoom } from "react-awesome-reveal";
import Block from "../../components/Block";
import { ButtonContainer, ContactContainer, Span } from "../../components/ContactForm/styles";
import { withTranslation } from "react-i18next";
import Input from "../../common/Input";
import { Button } from "../../common/Button";
import { validateEmail } from "../../common/utils/validationRules";
import api from "../../common/utils/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Container = lazy(() => import("../../common/Container"));

const ForgotPassword = ({ t }: any) => {

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("")

    const handleChange = (e: any) => {
        setEmail(e.target.value);
        setMessage(validateEmail(email))
    }

    const handleSubmit = () => {
        if (message === "") {
            api("POST", "/auth/password-reset/email", { email })
                .then(() => {
                    setMessage("A recovery link was sent. Please check the email")
                })
                .catch(() => {
                    setMessage("An unexpected error occured")
                })
        }
    }

    const ValidationType = (type: any) => {
        const ErrorMessage = message;
        return (
            <Zoom direction="left">
                <Span erros={type}>{ErrorMessage}</Span>
            </Zoom>
        );
    };


    return (
        <>
            <Header />
            <Container>
                <ContactContainer>
                    <Row justify="space-between" align="middle">
                        <Col lg={12} md={11} sm={24} xs={24}>
                            <Slide direction="left">
                                <Block title={"forgot_password_title"} content={"forgot_password_description"} />
                            </Slide>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Slide direction="right">
                                <Col span={24}>
                                    <Input
                                        type="text"
                                        name="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <ValidationType type="email" />
                                <ButtonContainer>
                                    <Button name="submit" onClick={handleSubmit}>{t("forgot_password_recover_link_button")}</Button>
                                </ButtonContainer>

                            </Slide>
                        </Col>
                    </Row>
                </ContactContainer>
            </Container>
            <Footer />
        </>
    );
};

export default withTranslation()(ForgotPassword);

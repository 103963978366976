import { Card, Col, Form, Input, Row, message } from "antd";
import { useState } from "react";
import api from "../../common/utils/api";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export interface PrivacyProps {
    t: any
}

const PrivacyBlock = ({
    t,
}: PrivacyProps) => {
    return (
        <>
            <Header />
            <div style={{margin:'5%'}}>
            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                1. Introduction
            </p>
            Welcome to RelayMate ("we," "our," or "us"). At RelayMate, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our services, including our website and mobile applications (collectively, the "Services"). By using our Services, you consent to the practices described in this Privacy Policy.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                2. Information We Collect
            </p>
            a. Email and Password: When you create an account with RelayMate, we collect your email address and password to provide you with access to our Services. Your password is securely hashed and stored to protect your account.

            b. Origin Information: In order to prevent multiple accounts per user, we may collect information about your origin, such as your IP address or other location-based data. This information is used solely for the purpose of ensuring the integrity of our user accounts.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                3. How We Use Your Information
            </p>
            We use the information we collect for various purposes, including but not limited to:

            a. Account Access: Your email and password are used to authenticate your identity and provide you access to your account.

            b. Preventing Multiple Accounts: Origin information is collected to prevent users from creating and maintaining multiple accounts.

            c. Communications: We may use your email address to send you important information, updates, and promotional material related to our Services. You can opt out of receiving promotional emails at any time by following the instructions provided in such emails.

            d. Improving Services: We may use aggregated and anonymized data to analyze and improve our Services, user experience, and customer support.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                4. Disclosure of Your Information
            </p>
            a. Service Providers: We may share your information with third-party service providers who assist us in delivering our Services. These providers have access to your information only to the extent necessary to perform their functions and are bound by confidentiality agreements.

            b. Legal Compliance: We may disclose your information if required to do so by law or in response to valid legal requests, such as court orders, subpoenas, or law enforcement requests.

            c. Business Transactions: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you of any such change in ownership or control of your personal information.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                5. Security
            </p>
            We take the security of your personal information seriously and implement appropriate measures to protect it. This includes encryption of data transmission, secure storage practices, and access controls. While we strive to protect your information, no data transmission or storage method is completely secure, and we cannot guarantee the security of your information.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                6. Your Choices
            </p>
            a. Account Information: You can review and update your account information by sending an email to: relaymate.contact@gmail.com. You may also request to delete your account, subject to legal and regulatory requirements.

            b. Marketing Communications: You can opt out of receiving marketing communications from us by following the unsubscribe instructions provided in our emails or by contacting us directly.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                7. Children's Privacy
            </p>
            Our Services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that a child under 13 has provided us with their personal information, please contact us, and we will take steps to delete such information.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                8. Changes to this Privacy Policy
            </p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other appropriate channels. Your continued use of our Services after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                9. Contact Us
            </p>
            If you have questions or concerns about this Privacy Policy or our data practices, please contact us at relaymate.contact@gmail.com.

            <p style={{ marginTop: '1%', marginBottom: '0%' }}>
                10. Conclusion
            </p>

            At RelayMate, we are committed to protecting your privacy and ensuring the security of your personal information. We value your trust and aim to be transparent about how we collect, use, and protect your information. Thank you for using our Services, and we look forward to serving you.
            </div>
            <Footer />
        </>
    );
}

export default withTranslation()(PrivacyBlock);
import { lazy } from "react";
import IntroContent from "../../content/IntroContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";
import AboutContent from "../../content/AboutContent.json";
import MissionContent from "../../content/MissionContent.json";
import ProductContent from "../../content/ProductContent.json";
import ContactContent from "../../content/ContactContent.json";
import QuestionsContent from "../../content/QuestionsContent.json";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Questions from "../../components/Questions";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));
const HomeContent = lazy(() => import("../../components/Home"))
const PurchaseContent = lazy(() => import("../../components/Purchase"))

const Home = () => {
  return (
    <>
    <Header/>
    <Container>
      <ScrollToTop />
      <HomeContent
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="truck.svg"
        id="intro"
      />
      <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
        id="download"
      />
      <ContentBlock
        type="left"
        title={AboutContent.title}
        content={AboutContent.text}
        section={AboutContent.section}
        icon="logistics.svg"
        id="features"
      />
      <ContentBlock
        type="right"
        section={MissionContent.section}
        icon="load.svg"
        id="features"
      />
      <Questions
        type="left"
        title={QuestionsContent.title}
        content={QuestionsContent.text}
        questions={QuestionsContent.questions}
        icon="question.svg"
        id="features"
      />
      <PurchaseContent
        type="left"
        title={ProductContent.title}
        content={ProductContent.text}
        icon="purchase.svg"
        id="purchase"
      />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY!}>
        <Contact
          title={ContactContent.title}
          content={ContactContent.text}
          id="contact"
        />
      </GoogleReCaptchaProvider>

    </Container>
    <Footer/>
    </>
  );
};

export default Home;

import axios, { Method } from 'axios';
import { API_BASE_URL, API_VERSION } from './constants';

axios.defaults.baseURL = API_BASE_URL + API_VERSION;
axios.defaults.xsrfCookieName = "XSRF-TOKEN"

export default function api<T>(
    method: Method,
    url: string,
    params = {},
    headers = {} ,
    noAuth: boolean = false,
    restOfConfig =  {}
) {
    const body = method === 'get' ? 'params' : 'data';
    const config = {
        method,
        url,
        [body]: params,
        headers: {
            ...headers,
            'Authorization': sessionStorage.getItem("token") || localStorage.getItem('token')
        },
        ...restOfConfig
    };
    return axios.request<T>(config);
}
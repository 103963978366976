import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const Button = ({
  color,
  fixedWidth,
  children,
  className,
  disabled,
  onClick,

}: ButtonProps) => (
  <StyledButton disabled={disabled} color={color} fixedWidth={fixedWidth} onClick={onClick} className = {className}>
    {children}
  </StyledButton>
);

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Popconfirm, Space, Table, message } from "antd";
import React from "react";
import api from "../../common/utils/api";

const { Column } = Table;

interface TicketData {
    id: string,
    ticketExpirationDate: string,
    activated: boolean,
    userId: string,
    userEmail: string
}

function Tickets() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource, setDataSource] = useState<TicketData[]>([]);

    useEffect(() => {
        getPersonalData()
        getTickets()
    }, []);

    const getPersonalData = () => {
        return api("GET", "/users/me", {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
            })
            .catch(error => {
                if (error && (error.response.status === 401 || error.response.status === 403)) {
                    navigate("/admin/login");
                }
            })
    }

    const getTickets = () => {
        return api<Array<TicketData>>("GET", "/tickets", {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
                setDataSource(response.data)
            })
            .catch(error => {
                if (error && error.response.data && error.response.data.debugMessage != undefined) {
                    errorMessage(error.response.data.debugMessage);
                } else {
                    errorMessage(error.message)
                }
            })
    }

    const handleAdd = () => {
        api("POST", `/tickets`, {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
                getTickets()
            })
            .catch(error => {
                if (error && error.response.data && error.response.data.debugMessage != undefined) {
                    errorMessage(error.response.data.debugMessage);
                } else {
                    errorMessage(error.message)
                }
            })
    };

    const handleDelete = (key: React.Key) => {
        api("DELETE", `/tickets/${key}`, {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
                getTickets()
            })
            .catch(error => {
                if (error && error.response.data && error.response.data.debugMessage != undefined) {
                    errorMessage(error.response.data.debugMessage);
                } else {
                    errorMessage(error.message)
                }
            })
    };

    const errorMessage = (content: string) => {
        messageApi.open({
            type: 'error',
            content: content,
            duration: 10
        });
    };

    return (
        <div>
            {contextHolder}
            <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add ticket
            </Button>
            <Table dataSource={dataSource} pagination={false}  style={{ height: '100%' }} rowKey={record => record.id}>
                <Column title="Id" dataIndex="id" key="id" width={200} />
                <Column title="Expiration date" dataIndex="expirationDate" key="expirationDate" width={200} />
                <Column title="Activated" dataIndex="activated" key="activated"
                    render={(_: any, record: TicketData) => (
                        <span>{String(record.activated)}</span>
                    )}
                />
                <Column title="User id" dataIndex="userId" key="userId" />
                <Column title="User email" dataIndex="userEmail" key="userEmail" width={200} />
                <Column
                    title="Action"
                    key="action"
                    fixed='right'
                    render={(_: any, record: TicketData) => (
                        <Space size="middle">
                            {dataSource.length >= 1 ? (
                                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                                    <a>Delete</a>
                                </Popconfirm>
                            ) : null}
                        </Space>
                    )}
                />
            </Table>
        </div>
    );
}

export default Tickets;
import { Card, Col, Form, Input, Layout, Menu, Row, message, theme } from "antd";
import { useEffect, useState } from "react";
import api from "../../common/utils/api";
import { LoginWrapper } from "./style";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import Users from "./users";
import Tickets from "./tickets";
import Header from "../../components/Header";

interface UserData {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    createdAt: string,
    role: string,
    ticketId?: string,
    ticketExpirationDate?: string,
    ticketActive: boolean
}

const Dashboard = () => {
    const navigate = useNavigate();
    const [selectedKey, setSelectedKey] = useState("0");


    const getPersonalData = () => {
        return api<UserData>("GET", "/users/me", {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response: any) => {})
            .catch(error => {
                if (error && (error.response.status === 401 || error.response.status === 403)) {
                    navigate("/admin/login");
                }
            })
    }

    useEffect(() => {
        getPersonalData()
    }, []);

    return (
        <>
        <Header/>
        <Layout className="site-layout" style={{ marginLeft: 100 }}>
            <Sider trigger={null} width={100}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <div className="logo" />
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['0']}
                    onClick={(e) => setSelectedKey(e.key)}
                    items={[
                        {
                            key: '0',
                            label: 'Users',
                        },
                        {
                            key: '1',
                            label: 'Tickets',
                        },
                        {
                            key: '3',
                            label: 'Logout',
                            style: {
                                marginTop: 250
                            },
                            onClick: (e) => {
                                localStorage.removeItem('token')
                                sessionStorage.removeItem("token")
                                navigate("/admin/login")
                            }
                        }
                    ]}
                />
            </Sider>
            <Layout>
                <Content style={{ margin: '10px 16px 0', overflow: 'initial' }}>
                    {selectedKey === '0' ? <Users /> :

                        selectedKey === '1' ? <Tickets /> : ''}
                </Content>
            </Layout>
        </Layout>
        </>
    );
}

export default withTranslation()(Dashboard);
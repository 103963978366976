import { Card, Col, Form, Input, Row, message } from "antd";
import api from "../../common/utils/api";
import { LoginWrapper } from "./style";
import { Button } from "../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export interface LogInProps {
    t: any
}

const LogInAdmin = ({
    t,
}: LogInProps) => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = async (values: any) => {
        await api("POST", "/auth/login", values, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response:any) => {
                console.log(response);
                
                if (response.status === 200) {
                    localStorage.setItem("token", response.data.token)
                    sessionStorage.setItem("token", response.data.token)
                    navigate("/admin/dashboard");
                }
                
            })
            .catch(error => {
                if (error && error.response.data && error.response.data.debugMessage !== undefined) {
                    errorMessage(error.response.data.debugMessage);
                } else {
                    errorMessage(error.message)
                }
            })
    };

    const errorMessage = (content: string) => {
        messageApi.open({
            type: 'error',
            content: content,
            duration: 10
        });
    };

    return (
        <>
        <Header />
        <LoginWrapper>
            <Row justify="center" className="row-size">
                {contextHolder}
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card>
                        <Col span={24} className="typo-grey typo-center">
                            <h2>Login</h2>
                        </Col>
                        <Form
                            layout="vertical"
                            onFinish={onFinish}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Email is required' },
                                    { type: 'email', message: 'Must be in email format' },
                                ]}
                                style={{ marginBottom: 15 }}
                            >
                                <Input name="email" type="email" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Password is required' }]}
                                style={{ marginBottom: 15 }}
                            >
                                <Input.Password name="password" />
                            </Form.Item>
                            <Form.Item>
                                <Button>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                        <Row>
                            <Link to={"/reset-password/email"} className='link-redirect'>
                                {t("forgot_password_link")}
                            </Link>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </LoginWrapper>
        <Footer/>
        </>
    );
}

export default withTranslation()(LogInAdmin);
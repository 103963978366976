import { Popconfirm, Space, Table, message } from "antd";
import { useEffect, useState } from "react";
import api from "../../common/utils/api";
import Column from "antd/lib/table/Column";
import { useNavigate } from "react-router-dom";

interface UserData {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    createdAt: string,
    role: string,
    ticketId?: string,
    ticketExpirationDate?: string,
    ticketActive: boolean
}

function Users() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource, setDataSource] = useState<UserData[]>([]);

    useEffect(() => {
        getPersonalData()
        getUsers()
    }, []);

    const getPersonalData = () => {
        return api<UserData>("GET", "/users/me", {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
            })
            .catch(error => {
                if (error && (error.response.status === 401 || error.response.status === 403)) {
                    navigate("/admin/login");
                }
            })

    }

    const getUsers = () => {
        return api<Array<UserData>>("GET", "/users", {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
                setDataSource(response.data)
            })
            .catch(error => {
                if (error && (error.response.status === 401 || error.response.status === 403)) {
                    navigate("/admin/login");
                }
            })

    }

    const handleDelete = (key: React.Key) => {
        api("DELETE", `/users/${key}`, {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
                getUsers()
            })
            .catch(error => {
                if (error && error.response.data && error.response.data.debugMessage != undefined) {
                    errorMessage(error.response.data.debugMessage);
                } else {
                    errorMessage(error.message)
                }
            })
    };

    const addTicket = (key: React.Key) => {
        api("POST", `/users/${key}/ticket`, {}, {}, false, { withCredentials: true, Credentials: "same-origin" })
            .then((response) => {
                getUsers()
            })
            .catch(error => {
                if (error && error.response.data && error.response.data.debugMessage != undefined) {
                    errorMessage(error.response.data.debugMessage);
                } else {
                    errorMessage(error.message)
                }
            })
    };

    const errorMessage = (content: string) => {
        messageApi.open({
            type: 'error',
            content: content,
            duration: 10
        });
    };

    return (
        <div>
            {contextHolder}
            <Table dataSource={dataSource} pagination={false}  style={{ height: '100%' }} rowKey={record => record.id}
                footer={(currentPageData) => {
                    return <span>{currentPageData.length}</span>
                }}
            >
                <Column title="Id" dataIndex="id" key="id" width={200} />
                <Column title="Name" dataIndex='name' key="name"
                    render={(_: any, record: UserData) => (
                        <span>{record.firstName} {record.lastName}</span>
                    )}
                />
                <Column title="Email" dataIndex="email" key="email" />
                <Column title="Role" dataIndex="role" key="role" />
                <Column title="Ticket Id" dataIndex="ticketId" key="ticketId" width={200} />
                <Column title="Ticket Expiration" dataIndex="ticketExpirationDate" key="ticketExpirationDate" width={200} />
                <Column title="CreatedAt" dataIndex="createdAt" key="createdAt" />

                <Column
                    title="Action"
                    key="action"
                    fixed='right'
                    render={(_: any, record: UserData) => (
                        <Space size="middle">
                            {dataSource.length >= 1 ? (
                                <div>
                                    <Popconfirm title="Add a month active ticket?" onConfirm={() => addTicket(record.id)}>
                                        <a>Add ticket</a>
                                    </Popconfirm>
                                    <br></br>
                                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                                        <a>Delete</a>
                                    </Popconfirm>
                                </div>
                            ) : null}
                        </Space>
                    )}
                />
            </Table>
        </div>
    );
}

export default Users;
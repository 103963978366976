import { Fade } from "react-awesome-reveal";
import { Content, ContentWrapper, LeftContentSection } from "../Home/style";
import { Col, Collapse, Row } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import { withTranslation } from "react-i18next";
import { QuestionsProps } from "./types";

const { Panel } = Collapse;

const Questions = ({
    title,
    content,
    questions,
    button,
    icon,
    t,
    id,
}: QuestionsProps) => {
    return (
        <LeftContentSection>
            <Fade direction="left">
                <Row justify="space-between" align="middle" id={id}>
                    <Col lg={10} md={10} sm={10} xs={24}>
                        <SvgIcon src={icon} width="100%" height="100%" />
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={24}>

                        <ContentWrapper>
                            <h6>{t(title)}</h6>
                            <Content>{t(content)}</Content>
                            <Collapse accordion bordered={false}>
                                {
                                    questions.map((question, index) => {
                                        
                                        return (
                                        <Panel header={t(question.question)} key={index}>
                                            <p>{t(question.answer)}</p>
                                        </Panel>
                                        )
                                    }
                                    )
                                }
                            </Collapse>
                        </ContentWrapper>
                    </Col>
                </Row>
            </Fade>
        </LeftContentSection>
    );
};

export default withTranslation()(Questions);
import { lazy, useState } from "react";
import { Col, Row } from "antd";
import { Slide, Zoom } from "react-awesome-reveal";
import Block from "../../components/Block";
import { ButtonContainer, ContactContainer, Span } from "../../components/ContactForm/styles";
import { withTranslation } from "react-i18next";
import Input from "../../common/Input";
import { Button } from "../../common/Button";
import { ValidationTypeProps } from "../../components/ContactForm/types";
import { validateConfirmPassword, validatePassword } from "../../common/utils/validationRules";
import api from "../../common/utils/api";
import Header from "../../components/Header";

const Container = lazy(() => import("../../common/Container"));

const ResetPassword = ({t}:any) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [messages, setMessages] = useState<any>({})
    const query = new URLSearchParams(window.location.search);

    const handlePasswordChange = (e: any) => {
        e.persist();
        var message = validatePassword(e.target.value)
        setMessages({ ...messages, [e.target.name]: message })
        setPassword(e.target.value);        
    }

    const handleConfirmPasswordChange = (e: any) => {
        var message = validateConfirmPassword(password, e.target.value)
        setMessages({ ...messages, [e.target.name]: message })
        setConfirmPassword(e.target.value);
    }

    const handleSubmit = () => {
        if (Object.keys(messages).length > 0 && messages.password === "" &&
            messages["confirm Password"] === "") {
            var body = {
                password,
                confirmPassword,
                token: query.get("token")
            }
            api("POST","/auth/password-reset",body)
            .then((res)=>{
                setMessages({ ...messages, password: "The password was changed successfully." })
                return res.data
            })
            .catch((error)=>{
                setMessages({ ...messages, password: "An unexpected error occured. Please call for another reset link." })
            })
        }

    }

    const ValidationType = ({ type }: ValidationTypeProps) => {
        const ErrorMessage = messages[type];
        return (
            <Zoom direction="left">
                <Span erros={messages[type]}>{ErrorMessage}</Span>
            </Zoom>
        );
    };

    return (
        <>
        <Header/>
        <Container>
            <ContactContainer>
                <Row justify="space-between" align="middle">
                    <Col lg={12} md={11} sm={24} xs={24}>
                        <Slide direction="left">
                            <Block title={"reset_password_title"} content={"reset_password_description"} />
                        </Slide>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <Slide direction="right">
                            <Col span={24}>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder="Your Password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </Col>
                            <Col span={24}>
                                <Input
                                    type="password"
                                    name="confirm Password"
                                    placeholder="Your Password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                            </Col>
                            <ButtonContainer>
                                <Button name="submit" onClick={handleSubmit}>{t("reset_password_recover_link_button")}</Button>
                            </ButtonContainer>

                            <ValidationType type="password" />
                            <ValidationType type="confirm Password" />
                        </Slide>
                    </Col>
                </Row>
            </ContactContainer >
        </Container >
        </>
    );
};

export default withTranslation()(ResetPassword);
